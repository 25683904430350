import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import cuid from 'cuid'

function DetailLine ({ label, values }) {
  const styles = useStyles()
  return (
    <div className={styles.detailLine}>
      <div className={styles.labelArea}>
        <span className={styles.label}>{label}:</span>
      </div>
      <div className={styles.detailValue}>
        {
          values.map(str => (
            <span key={cuid.slug()}>
              {str}
            </span>
          ))
        }
      </div>
    </div>
  )
};

const useStyles = makeStyles({
  detailLine: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
    color: '#757575'
  },
  labelArea: {
    width: '40%'
  },
  label: {
    fontWeight: '700'
  },
  detailValue: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'pre-wrap'
  }
})

DetailLine.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired
}

DetailLine.defaultProps = {}

export default DetailLine
