import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { TextField, Button, CircularProgress, FormHelperText } from '@material-ui/core'
import MaskedInput from 'react-text-mask'

// import classNames from 'classnames';
import CompletionDialog from '../CompletionDialog'
import ConfirmationDialog from '../ConfirmationDialog'
import ErrorDialog from '../ErrorDialog'
import PayoutInfo from '../PayoutInfo'
import { submitInvoice } from '../../utils/firebase.util'
import { Form, CurrencyFormat } from 'lib'

// TODO: move to lib
const URLMask = ({ inputRef, ...other }) => (
  <MaskedInput
    {...other}
    ref={ref => {
      inputRef(ref ? ref.inputElement : null)
    }}
    mask={(rawValue) => {
      if (!rawValue) return false
      const len = /^https:\/\//.test(rawValue)
        ? rawValue.trim().length - 8
        : rawValue.trim().length
      const mask = ['h', 't', 't', 'p', 's', ':', '/', '/'].concat(new Array(len).fill(/\S/))
      return mask
    }}
    placeholderChar={'\u2000'}
    placeholder='https://'
  />
)

const InvoiceForm = ({ details, service }) => {
  const
    cn = useStyles()
  const { quote } = service
  const [submitting, setSubmitting] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [helperText, setHelperText] = useState('')
  const [inputs, setInputs] = useState({
    finalPrice: '',
    trackLink: '',
    notes: ''
  })

  const handleInputChange = name => (event) => {
    setInputs({ ...inputs, [name]: event.target.value })
  }

  const onSubmit = async () => setConfirmOpen(true)
  const onConfirmClose = () => setConfirmOpen(false)
  const onConfirmSubmit = async () => {
    setConfirmOpen(false)
    setSubmitting(true)
    try {
      const finalPrice = Number(inputs.finalPrice) * 100
      await submitInvoice(finalPrice, service.type, inputs.notes, inputs.trackLink)
      setModalOpen(true)
    } catch (error) {
      setErrorText(error.message)
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    const minPrice = Number(quote.price.min) / 100
    const maxPrice = Number(quote.price.max) / 100
    const finalPrice = Number(inputs.finalPrice)

    if (finalPrice > maxPrice) { setHelperText(`Customer must approve a price greater than $${maxPrice}`) } else { setHelperText(`Quote: $${minPrice} - $${maxPrice}`) }
  }, [inputs, quote, setHelperText])

  return (
    <Form
      title="Invoice"
      info={
        'Submit your final invoice price for the move. If you have extra notes you\'d ' +
        'like to leave about the move, you can do so below.'
      }>
      <TextField
        className={cn.priceField}
        label="Final Price"
        id="final-price"
        value={inputs.finalPrice}
        InputProps={{ inputComponent: CurrencyFormat }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ maxValue: Number(quote.price.max) / 100 * 2 }}
        onChange={handleInputChange('finalPrice')}
        required/>
      <FormHelperText>{helperText}</FormHelperText>
      {!!inputs.finalPrice && <div className={cn.payoutInfo}>
        <PayoutInfo {...{
          details,
          serviceType: service.type,
          col1Price: inputs.finalPrice || 0
        }}/>
      </div>}
      <TextField
        className={cn.notesField}
        id="tracking-link"
        label="Tracking Link"
        InputProps={{ inputComponent: URLMask }}
        value={inputs.trackLink}
        onChange={handleInputChange('trackLink')}/>
      <TextField
        className={cn.notesField}
        label="Service Notes"
        id="notes"
        multiline
        rows={3}
        value={inputs.notes}
        onChange={handleInputChange('notes')}/>
      <div className={cn.buttonWrapper}>
        {submitting
          ? (
          <CircularProgress />
            )
          : (
          <Button
            variant="contained"
            className={cn.submitButton}
            onClick={onSubmit}
            disabled={inputs.finalPrice === ''}>
            Submit Invoice
          </Button>
            )}
      </div>
      <ConfirmationDialog
        modalOpen={confirmOpen}
        title={`Confirm final move price: $${inputs.finalPrice}`}
        messages={inputs.finalPrice > Number(quote.price.max) / 100
          ? ['Customer will need to approve with app before charge is made.']
          : null
        }
        onClose={onConfirmClose}
        onConfirm={onConfirmSubmit}
        confirmLabel="Submit"
      />
      <CompletionDialog
        modalOpen={modalOpen}
        message={[
          'Your invoice will be sent to the client.',
          'Thank you for your time!'
        ]}/>
      <ErrorDialog
        open={!!errorText}
        handleClose={() => setErrorText('')}
        title="Uh oh! Something Went Wrong."
        messages={[
          'Looks like there was an issue submitting your Invoice:',
          errorText
        ]}/>
    </Form>
  )
}

const useStyles = makeStyles({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '40px 75px',
    alignItems: 'center'
  },
  paperSurface: {
    maxWidth: '600px',
    width: '100%',
    padding: '20px'
  },
  leftColumn: {
    width: '50%',
    '@media (max-width: 550px)': {
      width: '100%'
    }
  },
  rightColumn: {
    width: '50%'
  },
  infoSpacer: {
    marginTop: '15px'
  },
  priceField: {
    maxWidth: '200px',
    marginTop: '10px'
  },
  payoutInfo: {
    marginTop: 10
  },
  notesField: {
    marginTop: '20px'
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    margin: '20px 0 10px',
    justifyContent: 'center'
  },
  submitButton: {
    width: '200px',
    marginTop: '10px',
    backgroundColor: '#0E75BB',
    color: 'white',
    '&:hover': {
      backgroundColor: '#78A3C0'
    }
  }
})

export default InvoiceForm
