import React from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import {
  Table as MuiTable, TableBody, TableRow as TRow, TableCell as MuiCell
} from '@material-ui/core'
import { Alert as MuiAlert, AlertTitle } from '@material-ui/lab'
import { $tr } from '../utils/app.util'

const TCell = withStyles({
  body: {
    color: 'unset',
    borderBottomWidth: 0
  },
  sizeSmall: { padding: '6px 6px' }
})(MuiCell)
const Table = withStyles({
  root: {
    marginLeft: 0,
    width: 'calc(100% + 34px)'
  }
})(MuiTable)
const Alert = withStyles({
  message: { width: '90%' }
})(MuiAlert)

const PayoutInfo = ({ details, serviceType, requestType, col1Price, col2Price, paymentMethod }) => {
  const cn = useStyles()
  // const { monetizeRate, monetizeCap, discount } = details
  const { discount } = details
  const isCustomWebApp = !!details.appID
  const serviceDiscount =
    ((discount?.percent || discount?.fixed) && (serviceType === 'move' || requestType === 'storageOnly')) && discount

  const column = (price) => {
    const p = Number(price)

    const discountAmt =
      !serviceDiscount
        ? 0
        : serviceDiscount?.percent
          ? Math.round(p * (serviceDiscount.percent))
          : serviceDiscount?.fixed
            ? serviceDiscount?.fixed
            : 0

    const cost = (p * 100) - discountAmt
    // 2024-04-23 muvaFee is to be included in the formerly called 'stripeFee'
    // in the near future muvaFee is going to be a SaaS charge

    // const muvaUncapped = cost * monetizeRate[serviceType]
    // const svcCap = monetizeCap[serviceType] || undefined
    // const muvaFee = (svcCap && muvaUncapped > svcCap) ? svcCap : muvaUncapped
    const muvaFee = 0
    const totalFee = paymentMethod === 'creditCard' ? 0.038 : 0.016
    const serviceFee = (cost - muvaFee) * totalFee
    const payout = (cost - muvaFee - serviceFee)
    // const svcCap = monetizeCap[serviceType] || undefined;
    // const muvaFee = (svcCap && muvaUncapped > svcCap) ? svcCap : muvaUncapped;
    // For the custom web apps (white label apps) this will become SaaS
    // For now, it will be a $0.
    // In the near future, we will have to check if it's a freight or a move
    // platform fee will then become a direct cap. ($500)
    // const muvaFee = 0;
    // const serviceFee = (cost - muvaFee) * 0.038;
    // const payout = (cost - muvaFee - serviceFee);

    return {
      discount: discountAmt,
      bookingFee: muvaFee,
      serviceFee,
      payout
    }
  }

  const col1 = column(col1Price)
  const col2 = column(col2Price)

  return (
    <Alert icon={false} severity="info">
      <AlertTitle>{ `Expected Payout ${paymentMethod === 'creditCard' ? '(Credit Card)' : '(Bank Transfer)'}` }</AlertTitle>
      <Table size="small"><TableBody>
        <TRow>
          <TCell></TCell>
          {!isCustomWebApp && <TCell>Min Price</TCell>}
          <TCell>{isCustomWebApp ? 'Est. Price' : 'Max Price'}</TCell>
        </TRow>
        {serviceDiscount > 0 && <TRow>
          <TCell>Discount</TCell>
          {!isCustomWebApp && <TCell>{$tr(col1.discount)}</TCell>}
          {!!col2Price && <TCell>{$tr(col2.discount)}</TCell>}
        </TRow>}
        {/* <TRow>
          <TCell>Platform Fee</TCell>
          <TCell>{$tr(col1.bookingFee, 0)}</TCell>
          {!!col2Price && <TCell>{$tr(col2.bookingFee, 0)}</TCell>}
        </TRow> */}
        <TRow style={{ borderBottomColor: '#8bc3f0' }}>
          <TCell>Payment Processing Fee</TCell>
          {!isCustomWebApp && <TCell>{$tr(col1.serviceFee, 0)}</TCell>}
          {!!col2Price && <TCell>{$tr(col2.serviceFee, 0)}</TCell>}
        </TRow>
        <TRow style={{ borderBottomWidth: 0 }}>
          <TCell className={cn.tableLastRow}><b>Your Payout</b></TCell>
          {!isCustomWebApp && <TCell className={cn.tableLastRow}><b>{$tr(col1.payout, 0)}</b></TCell>}
          {!!col2Price && <TCell className={cn.tableLastRow}><b>{$tr(col2.payout, 0)}</b></TCell>}
        </TRow>
      </TableBody></Table>
    </Alert>
  )
}

const useStyles = makeStyles({
  tableLastRow: {
    borderTop: '1px #8bc3f0 solid'
  }
})

export default PayoutInfo
