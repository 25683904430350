import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Button } from '@material-ui/core'

const useStyles = makeStyles({
  invoiceAuthForm: {
    width: '100%',
    height: '173px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  button: {
    width: '240px',
    marginTop: '10px',
    fontWeight: '600',
    backgroundColor: '#0E75BB',
    color: 'white',
    '&:hover': {
      backgroundColor: '#78A3C0'
    }
  }
})

function InvoiceAuthForm ({ onOpenAuthInc, oneOpenInvoice }) {
  const styles = useStyles()

  return <>
    <div className={styles.invoiceAuthForm}>
      <div />
      <Button
        variant="contained"
        className={styles.button}
        onClick={onOpenAuthInc}>
          Increase Payment Hold
        </Button>
      <Button
        variant="contained"
        className={styles.button}
        onClick={oneOpenInvoice}>
          Submit Final Cost
        </Button>
    </div>
  </>
}

InvoiceAuthForm.propTypes = {
  onOpenAuthInc: PropTypes.func.isRequired,
  oneOpenInvoice: PropTypes.func.isRequired
}

InvoiceAuthForm.defaultProps = {}

export default InvoiceAuthForm
