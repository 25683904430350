import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Paper } from '@material-ui/core'
import { time } from '../utils/app.util'
import DetailLine from './DetailLine'

const useStyles = makeStyles({
  detailsContainer: {
    boxSizing: 'border-box',
    width: '100%',
    padding: 30,
    paddingTop: 20
  },
  detailsTitle: {
    fontSize: 30,
    color: '#757575',
    paddingBottom: 5,
    borderBottom: '1px solid #757575'
  },
  detailsSection: {
    marginTop: '20px',
    fontSize: '14px'
  }
})

function QuoteDetails ({ details }) {
  const styles = useStyles()
  const info = details.details
  const { contact, pickup, destination, assigns, proID } = details
  const assign = assigns[proID]
  const formatDate = date => time(date).format('dddd, MMM Do, YYYY')

  return (
    <Paper className={styles.detailsContainer} elevation={1}>
      <div className={styles.detailsTitle}>Request Details</div>
      <div className={styles.detailsSection}>
        <DetailLine
          label="Customer"
          values={[
            contact.name,
            contact.email,
            contact.phone
          ]}
        />
        <DetailLine label="Move Type" values={[details.moveType]} />
        {details.priority &&
          <DetailLine
            label="Priorities"
            values={[details.priority?.join(', ')]}
          />
        }
        <DetailLine
          label="Pickup Address"
          values={[
            `${pickup.address}${pickup.apt ? `,${pickup.apt}` : ''}`,
            `${pickup.city}, ${pickup.state}, ${pickup.country}, ${pickup.zip}`
          ]}
        />
        <DetailLine label="Pickup Parking" values={[pickup.parking]} />
        <DetailLine label="Pickup Bedroom Count" values={[pickup.bedroomCount]} />
        <DetailLine
          label="Dropoff Address"
          values={[
            `${destination.address}${destination.apt ? `,${destination.apt}` : ''}`,
            `${destination.city}, ${destination.state}, ${destination.country}, ${destination.zip}`
          ]}
        />
        <DetailLine label="Dropoff Parking" values={[destination.parking]} />
        <DetailLine label="Dropoff Bedroom Count" values={[destination.bedroomCount]} />
        <DetailLine label="Preferred Move Date" values={[formatDate(details.details.moveDate)]} />
        <DetailLine label="Preferred Time Window" values={[details.details.moveWindow]} />
        { info.storageRequired &&
          <DetailLine label="Preferred Move-In Date" values={[formatDate(details.details.moveInDate)]} />
        }
        { (info.largeItems || info.noPacking || info.storageRequired) && (
          <DetailLine
            label="Packing Details"
            values={[
              info.largeItems && 'Large or Heavy Items',
              info.noPacking ? 'Self-pack' : 'Packing Required',
              info.boxes && 'Boxes Required',
              info.storageRequired && 'Storage Required'
            ]}
          />
        )}
        {info.requirements && (
          <DetailLine label="Requirements" values={[info.requirements]} />
        )}
        <DetailLine label="Customer Notes" values={[details.details.notes]} />
        <DetailLine label="Admin Notes" values={[assign?.notes]} />
      </div>
    </Paper>
  )
}

QuoteDetails.propTypes = {
  details: PropTypes.any.isRequired
}

export default QuoteDetails
