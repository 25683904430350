import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button
} from '@material-ui/core'

function ConfirmationDialog ({ modalOpen, title, messages, onClose, onConfirm, confirmLabel }) {
  return (
    <Dialog open={modalOpen}>
      <DialogTitle id="confirm-title">{title}</DialogTitle>
      {!!messages &&
        <DialogContent>
          <DialogContentText id="error-alert-description">
            { messages.map((line, i) => (
              <div key={line}>
                {i ? (<><br /></>) : null}{line}
              </div>
            ))}
          </DialogContentText>
        </DialogContent>
      }
      <DialogActions>
        <Button onClick={onConfirm}>{confirmLabel || 'Confirm'}</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  message: PropTypes.any.isRequired
}

ConfirmationDialog.defaultProps = {}

export default ConfirmationDialog
