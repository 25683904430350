import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Button } from '@material-ui/core'
import CompletionDialog from '../CompletionDialog'
import { moverReject } from '../../utils/firebase.util'
import { URLParams, AssignState } from '../../utils/app.util'
import RejectAlertDialog from '../RejectAlertDialog'
import ErrorDialog from '../ErrorDialog'

const useStyles = makeStyles({
  acceptDenyForm: {
    width: '100%',
    height: '173px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  acceptQuoteButton: {
    width: '240px',
    marginTop: '10px',
    fontWeight: '600',
    backgroundColor: '#0E75BB',
    color: 'white',
    '&:hover': {
      backgroundColor: '#78A3C0'
    }
  },
  rejectQuoteButton: {
    width: '240px',
    marginTop: '10px',
    fontWeight: '600'
  },
  rejectText: {
    marginBottom: '10px',
    color: '#757575',
    fontWeight: '600',
    cursor: 'pointer'
  }
})

function AcceptDenyForm ({ onOpenForm, details }) {
  const cn = useStyles()
  const { proID, assigns } = details
  const assign = assigns[proID]
  const [modalOpen, setModalOpen] = useState(false)
  const [openRejectAlert, setOpenRejectAlert] = React.useState(false)
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  function handleClickOpenRejectAlert () {
    setOpenRejectAlert(true)
  }

  function handleCloseRejectAlert () {
    setOpenRejectAlert(false)
  }

  const handleRejectClick = async () => {
    setSubmitting(true)
    if (URLParams().linkID === 'demo_review') {
      setTimeout(() => {
        setOpenRejectAlert(false)
        setModalOpen(true)
      }, 1500)
      return
    }
    try {
      await moverReject()
      setSubmitting(false)
      setOpenRejectAlert(false)
      setModalOpen(true)
    } catch (e) {
      setSubmitting(false)
      setOpenRejectAlert(false)
      setErrorModalOpen(true)
    }
  }

  return <>
    <div className={cn.acceptDenyForm}>
      <div />
      <Button
        variant="contained"
        className={cn.acceptQuoteButton}
        onClick={onOpenForm}>
        {assign.state === AssignState.assigned ? 'GIVE A QUOTE' : 'UPDATE QUOTE'}
      </Button>
      {assign.state !== AssignState.booked && <Button
        variant="text"
        size="small"
        className={cn.rejectQuoteButton}
        onClick={handleClickOpenRejectAlert}>
        REJECT QUOTE REQUEST
      </Button>}
    </div>
    <CompletionDialog
      modalOpen={modalOpen}
      message={[
        'Your unavailability has been noted.',
        'Thank you for your time!'
      ]}/>
    <RejectAlertDialog
      submitting={submitting}
      open={openRejectAlert}
      handleClose={handleCloseRejectAlert}
      handleReject={handleRejectClick}/>
    <ErrorDialog
      open={errorModalOpen}
      handleClose={() => setErrorModalOpen(false)}
      title="Uh oh! Something Went Wrong."
      message={
        'Looks like there was an issue submitting your Quote. Please' +
        ' try again. If you continue to have issues please contact' +
        ' us. Sorry for the inconvenience.'
      }/>
  </>
}

AcceptDenyForm.propTypes = {
  onOpenForm: PropTypes.func.isRequired
}

AcceptDenyForm.defaultProps = {}

export default AcceptDenyForm
