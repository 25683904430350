/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Paper } from '@material-ui/core'
import Iframe from 'react-iframe'
import Streetview from 'react-google-streetview'
import Script from 'react-load-script'
import { googleAPIKey } from '../utils/keys'
import Loading from './Loading'

const useStyles = makeStyles({
  container: {

  },
  section: {
    margin: '30px 0px',
    padding: 20
  },
  title: {
    fontSize: 30,
    color: '#757575',
    marginBottom: 10
  },
  streetView: {
    // width: 600,
    height: 400,
    margin: 0
  },
  map: {
    border: 0
  }
})

const QuoteMaps = ({ details }) => {
  const styles = useStyles()
  // note: headings only set once google maps has loaded to window.google.maps
  const [pickupViewHeading, setPickupViewHeading] = useState(null)
  const [dropoffViewHeading, setDropoffViewHeading] = useState(null)

  const formatAddress = addr =>
    encodeURIComponent(`${addr.address}, ${addr.city}, ${addr.state}`)

  const mapsLink = addr =>
    `https://maps.google.com/maps?q=${formatAddress(addr)}&t=&z=18&ie=UTF8&iwloc=&output=embed`

  // for static streetview
  // const streetViewLink = addr =>
  //   `https://maps.googleapis.com/maps/api/streetview?key=${googleAPIKey()}&size=600x400&location=${formatAddress(addr)}`;

  const directionsLink = (pickupAddr, dropoffAddr) =>
    `https://www.google.com/maps/embed/v1/directions?origin=place_id:${pickupAddr.placeID}&destination=place_id:${dropoffAddr.placeID}&key=${googleAPIKey()}`

  // sets headings for the streetview perspectives.
  const handleGoogleMapsLoad = () => {
    const googleMaps = window.google.maps
    const service = new googleMaps.StreetViewService()

    const getViewHeading = (geopoint, setViewHeading) => {
      const lookTo = new googleMaps.LatLng(geopoint)
      service.getPanorama({
        location: geopoint,
        preference: googleMaps.StreetViewPreference.BEST
      }, (res, status) => {
        if (status === googleMaps.StreetViewStatus.OK) {
          const lookFrom = res.location.latLng
          const heading = googleMaps.geometry.spherical.computeHeading(lookFrom, lookTo)
          setViewHeading(heading)
        } else {
          console.error(`failed to get view heading data: ${status}`)
        }
      })
    }

    getViewHeading(details.pickup.geopoint, setPickupViewHeading)
    getViewHeading(details.destination.geopoint, setDropoffViewHeading)
  }

  return (
    <div className={styles.container}>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${googleAPIKey()}`}
        onError={() => console.error('failed to load googlemaps')}
        onLoad={handleGoogleMapsLoad}
      />
      <Paper className={styles.section} elevation={1}>
        <div className={styles.title}>Directions:</div>
        <Iframe
          className={styles.map}
          url={directionsLink(details.pickup, details.destination)}
          width="100%"
          height="400px"
          id="pickup-map"
        />
      </Paper>
      <Paper className={styles.section} elevation={1}>
        <div className={styles.title}>Pickup:</div>
        <Iframe
          className={styles.map}
          url={mapsLink(details.pickup)}
          width="100%"
          height="400px"
          id="pickup-map"
        />
        <div className={styles.streetView}>
          { pickupViewHeading
            ? (
              <Streetview
                googleMaps={window.google.maps}
                streetViewPanoramaOptions={{
                  position: details.pickup.geopoint,
                  ...pickupViewHeading && {
                    pov: {
                      heading: pickupViewHeading,
                      pitch: 0
                    }
                  }
                }}
              />
              )
            : <Loading />
          }
        </div>
        <div><br />Note: StreetView may not point in the correct diretion</div>
      </Paper>
      <Paper className={styles.section} elevation={1}>
        <div className={styles.title}>Dropoff:</div>
        <Iframe
          className={styles.map}
          url={mapsLink(details.destination)}
          width="100%"
          height="400px"
          id="pickup-map"
        />
        <div className={styles.streetView}>
          { dropoffViewHeading
            ? (
              <Streetview
                googleMaps={window.google.maps}
                streetViewPanoramaOptions={{
                  position: details.destination.geopoint,
                  ...dropoffViewHeading && {
                    pov: {
                      heading: dropoffViewHeading,
                      pitch: 0
                    }
                  }
                }}
              />
              )
            : <Loading />
          }
        </div>
      </Paper>
    </div>
  )
}

QuoteMaps.propTypes = {
  details: PropTypes.any.isRequired
}

export default QuoteMaps
