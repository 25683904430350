import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

const OverlapContainer = ({ children, className }) => {
  const cn = useStyles()
  return (
    <div className={`${cn.overlapContainer} ${className || ''}`}>
      {children}
    </div>
  )
}

OverlapContainer.propTypes = {
  children: PropTypes.any.isRequired
}

const useStyles = makeStyles({
  overlapContainer: {
    // display: 'grid',
    // gridTemplateColumns: '1fr',
    // gridTemplateRows: '1fr',
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  }
})

export default OverlapContainer
