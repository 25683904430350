import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from '@material-ui/core/Button'
import { logout } from '../utils/firebase.util'
import { useIsMobile } from '../utils/hooks'
import logo from '../assets/logo.png'

const Navbar = ({
  title, loggedIn, hideContact, mover
}) => {
  const cn = useStyles()
  const isMobile = useIsMobile()
  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      <AppBar className={cn.navbar} position="static" color="default">
        <div className={cn.fullWidth}>
          <div className={cn.section}>
            <img src={logo} alt="logo" height={40} />
            {isMobile && <div className={cn.title}>{title}</div>}
          </div>
          {!isMobile && <span className={cn.title}>{title}</span>}
          <div className={cn.section}>
            <div className={cn.fullWidth}>
              {loggedIn && <Button className={cn.logOutButton} onClick={logout}>Log Out</Button>}
              {!hideContact &&
                <Button
                  variant="outlined"
                  className={cn.contactUsButton}
                  onClick={openModal}>
                  Contact Us
                </Button>
              }
            </div>
          </div>
        </div>
      </AppBar>

      {/* Contact Us Dialog */}
      <Dialog
        aria-labelledby="contact-us-dialog-title"
        aria-describedby="contact-us-dialog-info"
        open={modalOpen}
        onClose={closeModal}
        scroll="body"
      >
        <DialogTitle id="contact-us-dialog-title">Muva Contact Info</DialogTitle>
        <DialogContent>
          <DialogContentText id="contact-us-dialog-info">
            <b>Booking Support - Andrew Ritter</b>
            <br />
            Phone: <a href="tel:(775) 229-2149‬">(775) 229-2149‬</a>
            <br />
            Email: <a href="mailto:andrew@themuva.com">andrew@themuva.com</a>
            <br /><br />
            <b>Technical Support - Nick Swenson</b>
            <br />
            Email: <a href="mailto:info@themuva.com">info@themuva.com</a>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles({
  navbar: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 50px',
    '@media only screen and (max-width: 600px)': {
      padding: '5px 10px'
    }
  },
  fullWidth: {
    wdith: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fullWidthCenter: {
    extend: 'fullWidth',
    justifyContent: 'center'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    whiteSpace: 'nowrap'
  },
  countdown: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    marginRight: 15,
    paddingLeft: 5
  },
  contactUsButton: {
    height: 35,
    minWidth: 130,
    borderColor: '#0E75BB',
    color: '#0E75BB',
    '&:hover': {
      backgroundColor: '#0E75BB',
      color: 'White',
      borderColor: 'White'
    }
  },
  logOutButton: {
    minWidth: 90,
    marginRight: 15
  }
})

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  expireTime: PropTypes.any,
  orderID: PropTypes.string,
  loggedIn: PropTypes.bool,
  hideContact: PropTypes.bool,
  mover: PropTypes.string
}

Navbar.defaultProps = {
  expireTime: null,
  orderID: null,
  loggedIn: false,
  hideContact: false,
  mover: null
}

export default Navbar
