// deprecated, moved into action div

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

const OverlapDiv = ({ children, className, z }) => {
  const cn = useStyles(z)
  return (
    <div className={`${cn.overlapDiv} ${className || ''}`}>
      {children}
    </div>
  )
}

OverlapDiv.propTypes = {
  children: PropTypes.any.isRequired
}

const useStyles = makeStyles({
  overlapDiv: z => ({
    // gridColumnStart: 1,
    // gridRowStart: 1,
    width: '100%',
    marginLeft: '-100%',
    '&:first-of-type': {
      marginLeft: 'unset !important'
    },
    ...z && { zIndex: z }
  })
})

export default OverlapDiv
