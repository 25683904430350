import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

// const useStyles = makeStyles({});

const ErrorDialog = ({
  open, handleClose, title, message, messages
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="error-alert"
    aria-describedby="error-alert-description"
  >
    <DialogTitle id="error-alert">
      { title }
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="error-alert-description">
        { message || messages.map((line, i) => (
          <div key={line}>
            {i ? (<><br /></>) : null}{line}
          </div>
        ))}
        <br/>
        <div>
          Please contact <a href="mailto:info@themuva.com">info@themuva.com</a> for technical support.
        </div>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
)

ErrorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  messages: PropTypes.array
}

ErrorDialog.defaultProps = {
  message: ''
}

export default ErrorDialog
