import React, { useState } from 'react'
import { loadEstimatorItems, storeEstimatorItems } from '../utils/app.util'

import { Delete } from '@material-ui/icons'
import NumericInput from './NumericInput'
import { Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '0.25em',
    marginBottom: '0.25em'
  },
  itemContainer: {
    maxWidth: '480px',
    paddingLeft: '1em',
    paddingRight: '1em'
  },
  nameSpan: {
    maxWidth: '120px',
    width: '120px',
    marginRight: '10px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  quantityInput: {
    width: '50px',
    border: '1px solid #176BB5',
    borderRadius: '7px',
    marginRight: '10px',
    outline: 'none',
    padding: '5px',
    appearance: 'textarea'
  },
  result: {
    marginLeft: '10px',
    fontWeight: 'bold'
  },
  remove: {
    marginRight: '15px',
    backgroundColor: 'transparent',
    border: 'none',
    height: '22px',
    borderRadius: '50%',
    '&:hover': {
      color: 'blue',
      cursor: 'pointer'
    }
  }
})

export default function EstimatorItem (props) {
  const styles = useStyles()
  const [items, setItems] = useState(props.items)

  // useEffect(() => {
  //   setItems(props.items)
  // }, [props.items])

  const calculateResult = (amount, item) => {
    const itemsTmp = [...items]
    item.result = amount * item.cubiFtPerPiece
    item.numberOfItems = amount
    itemsTmp[items.map(e => e.name).indexOf(item.name)] = item
    setItems(itemsTmp)
    handleItemsStorage(itemsTmp)
  }

  const handleItemsStorage = (items) => {
    const storedItems = loadEstimatorItems()
    if (storedItems) {
      storedItems[items[0].category].items = items
      storeEstimatorItems(storedItems)
    }
  }

  const removeItem = (item) => {
    const index = items.map(e => e.name).indexOf(item.name)
    if (index === -1) return
    props.onRemove(item, index)
  }

  return (
    <div className={styles.itemContainer}>
      {items.map((item, index) => (
        <div key={`${item.name}_${index}`} className={styles.flex}>
          <button className={styles.remove} onClick={() => removeItem(item)}>
            <Delete fontSize='small'/>
          </button>
          <Tooltip title={item.name}>
            <span className={styles.nameSpan}>{item.name}</span>
          </Tooltip>
          <NumericInput min={0} onChange={(val) => calculateResult(val, item)} /> <b>×</b>&nbsp;<span>{item.cubiFtPerPiece} ft³  =  </span>
          <span className={styles.result}>{ item.result }</span>
        </div>
      ))}
    </div>
  )
}
