import React, { useState, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { NavigateBefore, NavigateNext, GetApp } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import { getVideoURL } from '../utils/firebase.util'
import VideoPlayer from './VideoPlayer'

const useStyles = makeStyles({
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 10
  },
  videoLabel: {
    width: 50,
    padding: '0 10px',
    textAlign: 'center',
    fontSize: 20
  }
})

const VideoSelect = ({ orderID, videos }) => {
  const
    classes = useStyles()
  const [urls, addUrl] = useReducer((state, { url, i }) => ({ ...state, [i]: url }), {})
  const numVideos = videos ? videos.length : 1
  const [curVideo, setCurVideo] = useState(0)
  const curUrl = videos ? urls[curVideo] : urls[0]

  useEffect(() => {
    if (videos) {
      videos.forEach((name, i) =>
        getVideoURL(name.includes('sample') ? `videos/sample/${name}` : `videos/${orderID}/${name}`)
          .then(url => addUrl({ url, i })))
    } else { console.error('no videos?') }
  }, [videos, orderID])

  return (
    <div>
      <VideoPlayer videoUrl={curUrl} />
      {videos && (
        <div className={classes.iconRow}>
          <IconButton
            onClick={() => setCurVideo(curVideo - 1)}
            disabled={curVideo <= 0}
          >
            <NavigateBefore/>
          </IconButton>
          <span className={classes.videoLabel}>{curVideo + 1} / {numVideos}</span>
          <IconButton
            onClick={() => setCurVideo(curVideo + 1)}
            disabled={curVideo >= videos.length - 1}
          >
            <NavigateNext/>
          </IconButton>
          <a href={curUrl} download>
            <IconButton><GetApp/></IconButton>
          </a>
        </div>
      )}
    </div>
  )
}

VideoSelect.propTypes = {
  videos: PropTypes.array,
  videoFilename: PropTypes.string
}

VideoSelect.defaultType = {
  videos: null,
  videoFilename: null
}

export default VideoSelect
