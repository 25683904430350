import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Dialog from '@material-ui/core/Dialog'
import cuid from 'cuid'
import logo from '../assets/logo.png'
import { userAgentIsMobile, reload } from '../utils/app.util'

const useStyles = makeStyles({
  completionDialog: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '50px'
  },
  messageArea: {
    whiteSpace: 'pre-wrap',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '20px',
    color: '#757575',
    fontWeight: '600'
  },
  messageFont: {
    fontSize: '20px'
  },
  mobileMessageFont: {
    fontSize: '14px'
  },
  messageLine: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center'
  },
  homeButton: {
    width: '240px',
    boxShadow: '0px 4px 10px -2px rgba(0,0,0,0.75)',
    marginBottom: '30px'
  }
})

function CompletionDialog ({ modalOpen, message, reloadTimeout }) {
  const styles = useStyles()

  useEffect(() => {
    if (modalOpen && reloadTimeout) setTimeout(() => reload(), reloadTimeout)
  })

  return (
    <Dialog open={modalOpen} fullScreen>
      <div className={styles.completionDialog}>
        <div className={`${styles.messageArea} ${userAgentIsMobile() ? styles.mobileMessageFont : styles.messageFont}`}>
          {
            message.map(line => (
              <span className={styles.messageLine} key={cuid.slug()}>
                {line}
              </span>
            ))
          }
        </div>
        <img src={logo} alt="logo" className={styles.logo} height={50} />
      </div>
    </Dialog>
  )
}

CompletionDialog.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  message: PropTypes.any.isRequired
}

CompletionDialog.defaultProps = {}

export default CompletionDialog
