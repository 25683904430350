import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, CircularProgress } from '@material-ui/core'

const classes = (arr) => arr.filter(Boolean).join(' ')

const useStyles = makeStyles({
  button: {
    position: 'relative',
    width: '240px',
    paddingRight: 0,
    fontWeight: '600',
    backgroundColor: '#0E75BB',
    color: 'white',
    '&:hover': {
      backgroundColor: '#78A3C0'
    },
    transition: [
      '0.3s width',
      '0.3s transform',
      '0.3s padding-right'
    ],
    marginTop: 10,
    '&:first-of-type': {
      marginTop: 0
    }
  },
  loadingButton: {
    paddingRight: 30,
    width: '270px',
    transform: 'translate(15px, 0)'
  },
  loading: {
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 230,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflow: 'hidden',
    transition: [
      '0.4s max-width'
    ]
  },
  loadingHide: {
    extend: 'loading',
    maxWidth: 0,
    transition: '0.2s max-width'
  },
  loadingShow: {
    extend: 'loading',
    maxWidth: 30
  }
})

const ActionButton = ({
  spaced, onClick, onError, children, loading, disabled, ...rest
}) => {
  const cn = useStyles()
  const [loadFound, setLoadFound] = useState(false)

  const handleClick = async () => {
    const ret = onClick()
    if (typeof loading === 'undefined' &&
        ret instanceof Promise) {
      setLoadFound(true)
      await ret
        .catch(err => { if (onError) onError(err); else throw err })
        .finally(() => setLoadFound(false))
    }
  }

  return (
    <Button
      variant="contained"
      className={classes([cn.button, spaced && cn.spaced, (loading || loadFound) && cn.loadingButton])}
      onClick={handleClick}
      disabled={disabled || loading || loadFound}
      {...rest}
    >
      {children}
      <div className={(loading || loadFound) ? cn.loadingShow : cn.loadingHide}>
        <CircularProgress size={20} color="primary" />
      </div>
    </Button>
  )
}

export default ActionButton
