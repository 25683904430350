import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Player, BigPlayButton, LoadingSpinner } from 'video-react'
import { useIsMobile, useWindowDimensions } from '../utils/hooks'
import { makeStyles } from '@material-ui/styles'
import poster from '../assets/poster.png'

const useStyles = makeStyles({
  videoPlayer: {
    position: 'relative'
  },

  video: {
    opacity: 0,
    visibility: 'hidden',
    transition: '300ms'
  },

  image: {
    opacity: 0,
    visibility: 'hidden',
    transition: '300ms',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },

  show: {
    opacity: 1,
    visibility: 'visible'
  }
})

const VideoPlayer = ({ videoUrl }) => {
  const [mimeType, setMimeType] = useState(null)
  const playerRef = useRef(null)
  const styles = useStyles()
  const isMobile = useIsMobile()
  const { width } = useWindowDimensions()
  const videoWidth = isMobile ? width * 0.85 : width < 1200 ? width * 0.45 : 600
  const videoHeight = videoWidth * 0.6

  useEffect(() => {
    if (videoUrl) {
      fetch(videoUrl)
        .then((response) => {
          const tmpMime = response.headers.get('Content-Type').split('/')[0]
          setMimeType(tmpMime)
          if (tmpMime === 'video') playerRef.current.load()
        })
        .catch((error) => console.error('Error:', error))
    }
  }, [videoUrl])

  return (
    <div className={styles.videoPlayer}>
      <Player
        ref={playerRef}
        playsInline
        poster={poster}
        fluid={false}
        width={videoWidth}
        height={videoHeight}
        key={`${videoUrl}-key`}
        className={
          styles.video + (mimeType === 'video' ? ' ' + styles.show : '')
        }
      >
        <BigPlayButton position="center" />
        <LoadingSpinner />
        <source src={videoUrl} />
      </Player>
      <div
        className={
          styles.image + (mimeType === 'image' ? ' ' + styles.show : '')
        }
        style={{
          backgroundImage: `url(${videoUrl})`,
          width: videoWidth,
          height: videoHeight
        }}
      />
    </div>
  )
}

VideoPlayer.propTypes = {
  fileName: PropTypes.string
}

VideoPlayer.defaultProps = {
  fileName: null
}

export default VideoPlayer
