import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

const CurrencyFormat = ({ inputRef, onChange, maxValue, ...other }) => {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({ target: { value: values.value } })
      }}
      isAllowed={values => maxValue ? values.value <= maxValue : true}
      allowNegative={false}
      thousandSeparator
      decimalScale={2}
      prefix="$"
    />
  )
}

CurrencyFormat.propTypes = {
  inputRef: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  maxValue: PropTypes.number
}

export default CurrencyFormat
