import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { TextField, Button, CircularProgress, FormHelperText } from '@material-ui/core'

import { increaseAuth } from '../../utils/firebase.util'
import { $tr } from '../../utils/app.util'
import { Form, CurrencyFormat } from 'lib'

import CompletionDialog from '../CompletionDialog'
import ConfirmationDialog from '../ConfirmationDialog'
import ErrorDialog from '../ErrorDialog'
import PayoutInfo from '../PayoutInfo'

const AuthIncreaseForm = ({ details, service }) => {
  const
    cn = useStyles()
  const { quote } = service
  const [submitting, setSubmitting] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [helperText] = useState(`Current Quote: ${$tr(quote.price.min)} - ${$tr(quote.price.max)}`)
  const [inputs, setInputs] = useState({ maxPrice: '' })

  const handleInputChange = name => (event) => {
    setInputs({ ...inputs, [name]: event.target.value })
  }

  const onSubmit = async () => setConfirmOpen(true)
  const onConfirmClose = () => setConfirmOpen(false)
  const onConfirmSubmit = async () => {
    setConfirmOpen(false)
    setSubmitting(true)
    try {
      const maxPrice = Number(inputs.maxPrice) * 100
      await increaseAuth(maxPrice, service.type)
      setModalOpen(true)
    } catch (error) {
      setErrorText(error.message)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Form
      title="Authorization Increase"
      info={
        'If the customer adds items to the move, or otherwise increases costs, you may ' +
        'add to the payment authorization hold on the customer\'s payment method, before you start labor. ' +
        'If successful, This will ensure payment for increased costs. If you have already done the move, ' +
        'and are submitting the final invoice, please instead use the Invoice Submission form, even if there ' +
        'are additional costs.'
      }>
      <TextField
        className={cn.priceField}
        label="New Max Price"
        id="quote-price"
        value={inputs.maxPrice}
        InputProps={{ inputComponent: CurrencyFormat }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        onChange={handleInputChange('maxPrice')}
        required/>
      <FormHelperText>{helperText}</FormHelperText>
      {!!inputs.maxPrice && <div className={cn.payoutInfo}>
        <PayoutInfo {...{
          details,
          serviceType: service.type,
          col1Price: inputs.maxPrice || 0
        }}/>
      </div>}

      <div className={cn.buttonWrapper}>
        {submitting
          ? (
          <CircularProgress />
            )
          : (
          <Button
            variant="contained"
            className={cn.submitButton}
            onClick={onSubmit}
            disabled={inputs.maxPrice <= quote.price.max / 100}>
            Submit
          </Button>
            )}
      </div>
      <ConfirmationDialog
        modalOpen={confirmOpen}
        title={`Confirm new max price: $${inputs.maxPrice}`}
        onClose={onConfirmClose}
        onConfirm={onConfirmSubmit}
        confirmLabel="Submit Authorization"
      />
      <CompletionDialog
        modalOpen={modalOpen}
        reloadTimeout={2500}
        message={['Payment hold increased.']}/>
      <ErrorDialog
        open={!!errorText}
        handleClose={() => setErrorText('')}
        title="Uh oh! Something Went Wrong."
        messages={[
          'Looks like there was an issue submitting your increase:',
          errorText
        ]}/>
    </Form>
  )
}

const useStyles = makeStyles({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '40px 75px',
    alignItems: 'center'
  },
  paperSurface: {
    maxWidth: '600px',
    width: '100%',
    padding: '20px'
  },
  leftColumn: {
    width: '50%',
    '@media (max-width: 550px)': {
      width: '100%'
    }
  },
  rightColumn: {
    width: '50%'
  },
  infoSpacer: {
    marginTop: '15px'
  },
  priceField: {
    maxWidth: '200px',
    marginTop: '10px'
  },
  payoutInfo: {
    marginTop: 10
  },
  notesField: {
    marginTop: '20px'
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    margin: '20px 0 10px',
    justifyContent: 'center'
  },
  submitButton: {
    width: '200px',
    marginTop: '10px',
    backgroundColor: '#0E75BB',
    color: 'white',
    '&:hover': {
      backgroundColor: '#78A3C0'
    }
  }
})

export default AuthIncreaseForm
