import { IconButton, TextField, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'

import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    margin: '0 8px'
  },
  textField: {
    width: '40px',
    textAlign: 'center'
  }
})

const NumericInput = ({ min, max, initialValue = 0, onChange = () => {} }) => {
  const classes = useStyles()
  const [value, setValue] = useState(initialValue)

  const callOnChange = (val) => {
    if (typeof onChange === 'function') onChange(val)
  }

  const handleIncrement = () => {
    if (max === undefined || value < max) {
      setValue(value + 1)
      callOnChange(value + 1)
    }
  }

  const handleDecrement = () => {
    if (min === undefined || value > min) {
      setValue(value - 1)
      callOnChange(value - 1)
    }
  }

  return (
    <span className={classes.container}>
      <IconButton size='small' className={classes.button} onClick={handleDecrement}>
        <RemoveIcon />
      </IconButton>
      <TextField
        value={value}
        className={classes.textField}
        inputProps={{
          readOnly: true,
          style: { textAlign: 'center' }
        }}
      />
      <IconButton size='small' className={classes.button} onClick={handleIncrement}>
        <AddIcon />
      </IconButton>
    </span>
  )
}

export default NumericInput
