import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

const NumberFormatCustom = ({ inputRef, onChange, ...other }) => {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({ target: { value: values.value } })
      }}
      inputMode="numeric"
      allowNegative={false}
      thousandSeparator
      decimalScale={2}
      prefix="$"
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
}

export default NumberFormatCustom
