import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import CompletionDialog from '../components/CompletionDialog'
import ReviewQuoteMain from './ReviewQuoteMain'
import Loading from '../components/Loading'
import { getLinkInfo, acceptTerms } from '../utils/firebase.util'
import TermsDialog from '../components/TermsDialog'

const useStyles = makeStyles({
  appContainer: {
    height: '100%'
  }
})

const ReviewQuotePage = () => {
  const styles = useStyles()
  const [loading, setLoading] = useState(true)
  const [details, setDetails] = useState({})
  const [errorModal, setErrorModal] = useState(false)
  const [termsLoading, setTermsLoading] = useState(false)
  const [termsModalOpen, setTermsModalOpen] = useState(false)
  const [message, setMessage] = useState([
    'This link is no longer active.',
    'We apologize for the inconvenience.'
  ])

  const acceptMoverTerms = () => {
    setTermsLoading(true)
    acceptTerms()
      .then(() => {
        setTermsLoading(false)
        setTermsModalOpen(false)
      })
      .catch(() => {
        setTermsLoading(false)
        setMessage([
          'Uh oh! Looks like something went wrong.',
          'Either this link is no longer active, or there was an error.',
          'We apologize for the inconvenience'
        ])
        setErrorModal(true)
      })
  }

  const rejectTerms = () => {
    setMessage([
      'Uh oh! Looks like you rejected our Terms of Service.',
      "If you'd like to accept them please refresh the page.",
      'If you have an issue with our terms please contact us directly.'
    ])
    setErrorModal(true)
  }

  useEffect(() => {
    setLoading(true)
    getLinkInfo()
      .then(res => {
        console.log(res)
        setDetails(res)
        setTermsModalOpen(!res.termsAccepted || !res.termsUpdateAccepted)
        setLoading(false)
      })
      .catch(() => {
        setMessage([
          'Uh oh! Looks like something went wrong.',
          'Either this link is no longer active, or there was an error.',
          'We apologize for the inconvenience'
        ])
        setErrorModal(true)
      })
  }, [])

  return (
    <div className={styles.appContainer}>
      {loading ? <Loading /> : <ReviewQuoteMain details={details} />}
      <CompletionDialog
        modalOpen={errorModal}
        message={message}
      />
      <TermsDialog
        modalOpen={termsModalOpen}
        onAccept={acceptMoverTerms}
        onReject={rejectTerms}
        loading={termsLoading}
        details={details}
      />
    </div>
  )
}

export default ReviewQuotePage
