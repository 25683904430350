import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Button, CircularProgress } from '@material-ui/core'

const useStyles = makeStyles({
  button: {
    width: '240px',
    fontWeight: '600',
    backgroundColor: '#0E75BB',
    color: 'white',
    '&:hover': {
      backgroundColor: '#78A3C0'
    },
    transition: '0.3s width, 0.3s transform'
  },
  spacedButton: {
    extend: 'button',
    marginTop: '10px'
  },
  loadingButton: {
    width: '270px',
    transform: 'translate(15px, 0)'
  },
  loading: {
    display: 'flex',
    transition: '0.3s max-width ease-out, 0.3s padding-left'
  },
  loadingHide: {
    extend: 'loading',
    maxWidth: '0px'
    // display: 'none !important',
  },
  loadingShow: {
    extend: 'loading',
    maxWidth: '30px',
    paddingLeft: '20px'
  }
})

const ActionButton = ({
  spaced, onClick, children, loading, disabled, ...rest
}) => {
  const classes = useStyles()
  const [loadFound, setLoadFound] = useState(false)

  const handleClick = async () => {
    const ret = onClick()
    if (typeof loading === 'undefined' &&
        ret instanceof Promise) {
      setLoadFound(true)
      await ret
        .finally(() => setLoadFound(false))
    }
  }

  return (
    <Button
      variant="contained"
      className={`${classes.button} ${spaced ? classes.spacedButton : ''} ` +
        `${loading || loadFound ? classes.loadingButton : ''}`}
      onClick={handleClick}
      disabled={disabled || loading || loadFound}
      {...rest}
    >
      {children}
      <div className={(loading || loadFound) ? classes.loadingShow : classes.loadingHide}>
        <CircularProgress size={20} color="primary" />
      </div>
    </Button>
  )
}

ActionButton.propTypes = {
  spaced: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
}

ActionButton.defaultProps = {
  spaced: false,
  disabled: false,
  loading: undefined
}

export default ActionButton
