import React from 'react'
import { makeStyles } from '@material-ui/styles'

const Form = ({ children, title, strongHeader, info }) => {
  const cn = useStyles()

  return (
    <>
      <div className={cn.header}>
        <div className={strongHeader ? cn.centerTitle : cn.title}>{title}</div>
        {!!info && <div className={cn.info}>{info}</div>}
      </div>
      <div className={cn.form}>
        {children}
      </div>
    </>
  )
}

const useStyles = makeStyles({
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontSize: '25px',
    marginBottom: '5px',
    color: '#176BB5'
  },
  centerTitle: {
    extend: 'title',
    paddingTop: 5,
    paddingBottom: 5,
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#F6F6F6'
  },
  info: {
    fontSize: '12px',
    marginBottom: '20px'
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
})

export default Form
