import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import { TextField, Grid, FormControlLabel, Checkbox, Tooltip, IconButton, Typography } from '@material-ui/core'
import { Alert as MuiAlert, AlertTitle } from '@material-ui/lab'
import HelpIcon from '@material-ui/icons/Help'

import { capitalize } from '../../utils/app.util'
import MoneyNumberFormat from '../MoneyNumberFormat'
import PayoutInfo from '../PayoutInfo'
import HelperText from '../HelperText'
import { Form } from 'lib'

const TEXT_FIELD_VARIANT = 'outlined'
const MAX_PRICE_LIMIT_MULT = 1.5

const Alert = withStyles({
  message: { width: '100%' }
})(MuiAlert)

const ServiceForm = ({ details, serviceType, inputs, onInput, setComplete }) => {
  const cn = useStyles()
  const { minDepositInfo, assigns, proID } = details
  const assign = assigns[proID]
  const requestType = // used to select what fields to display
    assign.services.length > 1
      ? 'moveComplex'
      : assign.services.includes('move')
        ? 'moveSimple'
        : assign.services.includes('storage')
          ? 'storageOnly'
          : 'unknown'

  const title =
    requestType === 'moveComplex'
      ? (
          serviceType === 'move'
            ? 'Move: Pickup -> Storage'
            : serviceType === 'moveIn'
              ? 'Move: Storage -> Dropoff'
              : serviceType === 'storage'
                ? 'Storage'
                : 'Service')
      : capitalize(serviceType)

  const description =
    serviceType === 'move'
      ? 'Fee charged on the day of move.'
      : serviceType === 'moveIn'
        ? 'Fee charged for move after storage.'
        : ''

  const ccAndBT = 'This permits your customer to pay with either Credit Card or Bank Transfer'
  const BTOnly = 'This permits your customer to only pay via Bank Transfer'

  const isCustomWebApp = !!details.appID

  const [priceError, setPriceError] = useState('')
  const [recurPriceError, setRecurPriceError] = useState('')
  const [cancelFeeError, setCancelFeeError] = useState('')
  const [notesError, setNotesError] = useState('')
  const [depositError, setDepositError] = useState('')
  const [numMoversError, setNumMoversError] = useState('')
  const [numTrucksError, setNumTrucksError] = useState()
  const [paymentMethodError, setPaymentMethodError] = useState('')
  const [paymentCCDisabled, setPaymentCCDisabled] = useState(false)
  const [paymentACHDisabled, setPaymentACHDisabled] = useState(false)
  const [dateError, setDateError] = useState('')
  // [inputs, setInputs] = useState(initInputs(details, serviceType));

  const onInputChange = name => (evt) => {
    if (evt.persist) evt.persist()
    onInput({ ...inputs, [name]: evt.target.value })
    // console.log({ inputs })
  }
  const onCheckChange = name => (evt) => {
    if (evt.persist) evt.persist()

    // if cc payment is selected, auto select ach and disable (both payments accepted)
    if (name === 'paymentCC') {
      // inputs.paymentBankTransfer = evt.target.checked
      setPaymentACHDisabled(evt.target.checked)
    }
    // if ach payment is selected, disable cc payment (ach transfer only)
    if (name === 'paymentBankTransfer') setPaymentCCDisabled(evt.target.checked)

    onInput({ ...inputs, [name]: evt.target.checked })
    // console.log(inputs)
  }

  // input error checking
  useEffect(() => {
    const { maxPrice, minPrice, recurPrice, lateCancelFee, deposit, numMovers, numTrucks, paymentBankTransfer, paymentCC, date } = inputs

    if (!minPrice) {
      setPriceError('')
    } else if (!Number(minPrice)) {
      setPriceError('Price required')
    } else if (!maxPrice) {
      setPriceError('')
    } else if (Number(maxPrice) < Number(minPrice)) {
      setPriceError('Max price must be greater.')
    } else if ((Number(maxPrice) > (Number(minPrice) * MAX_PRICE_LIMIT_MULT))) {
      setPriceError(`Max price limit is ${MAX_PRICE_LIMIT_MULT}x min`)
    } else {
      setPriceError('')
    }
    if (isCustomWebApp && Number(maxPrice) < 1) {
      setPriceError('Max price must be $1 or greater')
    } else {
      setPriceError('')
    }

    if (recurPrice && !Number(recurPrice)) {
      setRecurPriceError('Monthly extension price required')
    } else {
      setRecurPriceError('')
    }

    if (lateCancelFee && Number(lateCancelFee) > Number(minPrice)) {
      setCancelFeeError('Fee can not be greater than the min quote price.')
    } else if (lateCancelFee) {
      setCancelFeeError('')
    }
    if (Number(lateCancelFee) < 1) {
      setCancelFeeError('Late cancel fee must be $1 or greater')
    } else if (lateCancelFee) {
      setCancelFeeError('')
    }

    if (serviceType === 'move' && Number(deposit) < Number(minDepositInfo.amount / 100)) {
      setDepositError(minDepositInfo.error)
    } else if (minPrice && Number(deposit) > Number(minPrice)) {
      setDepositError('Deposit cannot be greater than the min quote price.')
    } else if (Number(deposit) < 1) {
      setDepositError('Deposit should be $1 or greater')
    } else {
      setDepositError('')
    }

    if (Number(numMovers) < 1) {
      setNumMoversError('The number of movers must be 1 or greater')
    } else {
      setNumMoversError('')
    }
    if (Number(numTrucks) < 1) {
      setNumTrucksError('The number of trucks must be 1 or greater')
    } else {
      setNumTrucksError('')
    }

    if (!paymentCC && !paymentBankTransfer) {
      setPaymentMethodError('You must select a payment method')
    } else {
      setPaymentMethodError('')
    }

    const dateTmp = new Date(new Date(date).toDateString())
    const today = new Date(new Date().toDateString())
    if (dateTmp < today) {
      setDateError('Date must not be earlier than today')
    } else {
      setDateError('')
    }
    // console.log({ priceError, depositError, cancelFeeError, notesError, numMoversError, numTrucksError, paymentMethodError, dateError })
  }, [inputs])

  // ensure movers don't enter prices into notes
  //  (deprecated after taking platform fee from movers)
  useEffect(() => {
    // regex match for $<numbers>
    if (inputs.notes && inputs.notes.match(/\$[\s]*[\d]+/g)) {
      setNotesError('Please do not state prices directly in notes.')
    } else {
      setNotesError('')
    }
  }, [inputs?.notes])

  // form completion check
  useEffect(() => {
    const noMissing =
      (serviceType === 'move' &&
        !!inputs.date &&
        !!inputs.time &&
        !!inputs.numMovers &&
        !!inputs.numTrucks &&
        // !!Number(inputs.minPrice) &&
        !!Number(inputs.maxPrice) &&
        !!Number(inputs.deposit) &&
        !!inputs.lateCancelFee &&
        (!inputs.paymentCC || !inputs.paymentBankTransfer)) ||
      (serviceType === 'moveIn' &&
        !!inputs.date &&
        !!inputs.time &&
        !!inputs.numMovers &&
        !!inputs.numTrucks &&
        !!Number(inputs.minPrice) &&
        !!Number(inputs.maxPrice)) ||
      (requestType === 'storageOnly' &&
        !!inputs.date &&
        !!inputs.time &&
        !!Number(inputs.recurPrice)) ||
      (serviceType === 'storage' &&
        !!Number(inputs.recurPrice))

    setComplete(
      noMissing &&
      !priceError &&
      !depositError &&
      !cancelFeeError &&
      !numMoversError &&
      !numTrucksError &&
      !paymentMethodError &&
      !dateError &&
      !notesError
    )
    console.log({ noMissing, priceError, depositError, cancelFeeError, notesError, paymentMethodError })
  }, [inputs, priceError, depositError, cancelFeeError, notesError, numMoversError, numTrucksError, paymentMethodError])

  const InputLabelProps = {
    shrink: true,
    className: cn.inputLabel
  }

  return (
    <Form title={title} info={description} strongHeader>
      <Grid container className={cn.formSection} spacing={2}>
      {/* <DatePicker
        id="move-date"
        className={cn.formLine}
        variant="inline"
        label="Move Date"
        format="MMMM D, YYYY"
        value={inputs.moveDate}
        onChange={handlePickerChange('moveDate')}
        disableToolbar
        disablePast
        autoOk
      />
      <TimePicker
        id="pickup-time"
        className={cn.formLine}
        variant="inline"
        label="Move Time"
        value={inputs.moveTime}
        onChange={handlePickerChange('moveTime')}
        disableToolbar
        ampm
        autoOk
      /> */}
      {//* * date time for services **/:
        (serviceType.includes('move') || requestType === 'storageOnly') && <>
        <Grid item xs={6} sm={12} md={6}>
          {<TextField
            className={cn.textField}
            variant={TEXT_FIELD_VARIANT}
            label="Date"
            InputLabelProps={InputLabelProps}
            type="date"
            placeholder="mm/dd/yyyy"
            value={inputs.date}
            onChange={onInputChange('date')}
            error={!!dateError}
            required/>}
          <HelperText error={!!dateError}>
            {dateError}
          </HelperText>
        </Grid>
        <Grid item xs={6} sm={12} md={6}>
          <TextField
            className={cn.textField}
            variant={TEXT_FIELD_VARIANT}
            label="Time"
            InputLabelProps={InputLabelProps}
            type="time"
            inputProps={{ step: 300 }}
            value={inputs.time}
            onChange={onInputChange('time')}
            required/>
        </Grid>
      </>}
      {//* * move pricing **/
        serviceType.includes('move') && <>
        <Grid item xs={6}>
          <TextField
            className={cn.textField}
            variant={TEXT_FIELD_VARIANT}
            label="Num Movers"
            InputLabelProps={InputLabelProps}
            type="number"
            value={inputs.numMovers}
            inputProps={{ min: 1 }}
            onChange={onInputChange('numMovers')}
            error={!!numMoversError}
            required/>
            <HelperText error={!!numMoversError}>
              {numMoversError}
            </HelperText>
        </Grid>
        <Grid item xs={6}>
          <TextField
            className={cn.textField}
            variant={TEXT_FIELD_VARIANT}
            label="Num Trucks"
            InputLabelProps={InputLabelProps}
            type="number"
            value={inputs.numTrucks}
            inputProps={{ min: 1 }}
            onChange={onInputChange('numTrucks')}
            error={!!numTrucksError}
            required/>
            <HelperText error={!!numTrucksError}>
              {numTrucksError}
            </HelperText>
        </Grid>
        { !isCustomWebApp &&
            <Grid item xs={6}>
              <TextField
                className={cn.textField}
                variant={TEXT_FIELD_VARIANT}
                label="Est Min Price"
                InputLabelProps={InputLabelProps}
                InputProps={{ inputComponent: MoneyNumberFormat }}
                value={inputs.minPrice}
                onChange={onInputChange('minPrice')}
                error={!!priceError}
                required/>
            </Grid>

        }
        <Grid item xs={isCustomWebApp ? 12 : 6}>
          <TextField
            className={cn.textField}
            variant={TEXT_FIELD_VARIANT}
            label={isCustomWebApp ? 'Est Price' : 'Est Max Price'}
            InputLabelProps={InputLabelProps}
            InputProps={{ inputComponent: MoneyNumberFormat }}
            value={inputs.maxPrice}
            onChange={onInputChange('maxPrice')}
            error={!!priceError}
            required/>
        </Grid>
        <Grid item xs={12} className={cn.lessSpace}>
          <HelperText error={!!priceError}>{priceError}</HelperText>
        </Grid>
        {serviceType === 'move' &&
          <Grid item xs={12} className={cn.lessSpace}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <FormControlLabel control={
                <Checkbox
                  color="primary"
                  checked={inputs.maxGuarantee}
                  onChange={onCheckChange('maxGuarantee')}
                />
              }
              label={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography>Max Price Guarantee</Typography>
                <Tooltip title={details.terms?.maxPrice} arrow>
                  <IconButton size="small"><HelpIcon fontSize="small"/></IconButton>
                </Tooltip>
              </div>}/>
            </div>
          </Grid>
        }
        {<Grid item xs={12} className='cn.lessSpace'>
          <Alert icon={false} severity={paymentMethodError ? 'error' : 'info'}>
            <AlertTitle>Choose a Payment method</AlertTitle>
          </Alert>
          <HelperText error={!!paymentMethodError}>
              {paymentMethodError}
            </HelperText>
          <div style={{ display: 'flex', justifyContent: 'start' }}>
            <FormControlLabel control={
              <Checkbox
                color="primary"
                checked={inputs.paymentCC || false}
                onChange={onCheckChange('paymentCC')}
                disabled={paymentCCDisabled}
              />
            }
            label={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography>Accept Both Credit Card & Bank Transfer</Typography>
              <Tooltip title={ccAndBT} arrow>
                <IconButton size="small"><HelpIcon fontSize="small"/></IconButton>
              </Tooltip>
            </div>}/>
          </div>
        </Grid>
        }
        {!!inputs.maxPrice && <Grid item xs={12}>
          <PayoutInfo {...{
            details,
            serviceType,
            requestType,
            col1Price: inputs.minPrice || '0',
            col2Price: inputs.maxPrice || '0',
            paymentMethod: 'creditCard'
          }}/>
        </Grid>}
        {<Grid item xs={12} className='cn.lessSpace'>
          <div style={{ display: 'flex', justifyContent: 'start' }}>
            <FormControlLabel control={
              <Checkbox
                color="primary"
                checked={inputs.paymentBankTransfer || false}
                onChange={onCheckChange('paymentBankTransfer')}
                disabled={paymentACHDisabled}
              />
            }
            label={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography>Accept Only Bank Transfer</Typography>
              <Tooltip title={BTOnly} arrow>
                <IconButton size="small"><HelpIcon fontSize="small"/></IconButton>
              </Tooltip>
            </div>}/>
          </div>
        </Grid>
        }
        {!!inputs.maxPrice && <Grid item xs={12}>
          <PayoutInfo {...{
            details,
            serviceType,
            requestType,
            col1Price: inputs.minPrice || '0',
            col2Price: inputs.maxPrice || '0',
            paymentMethod: 'bankTransfer'
          }}/>
        </Grid>}
      </>}
      {//* * storage pricing **/
        serviceType === 'storage' && <>
        {requestType === 'moveComplex' && <>
          <Grid item xs={6}>
            <TextField
              className={cn.textField}
              variant={TEXT_FIELD_VARIANT}
              label="Prepaid Price"
              InputLabelProps={InputLabelProps}
              InputProps={{ inputComponent: MoneyNumberFormat }}
              value={inputs.initialPrice}
              onChange={onInputChange('initialPrice')}
              required
            />
            <HelperText>Paid on move, covers until move in.</HelperText>
          </Grid>
        </>}
        <Grid item xs={6}>
          <TextField
            className={cn.textField}
            variant={TEXT_FIELD_VARIANT}
            label="Extension Price"
            InputLabelProps={InputLabelProps}
            InputProps={{ inputComponent: MoneyNumberFormat }}
            value={inputs.recurPrice}
            onChange={onInputChange('recurPrice')}
            error={!!recurPriceError}
            required
          />
          {!recurPriceError
            ? <HelperText>Paid monthly upon extension</HelperText>
            : <HelperText error>{recurPriceError}</HelperText>
          }
        </Grid>
        {(!!inputs.initialPrice || !!inputs.recurPrice) && <Grid item xs={12}>
          <PayoutInfo {...{
            details,
            serviceType,
            requestType,
            col1Price: inputs.initialPrice || '0',
            col2Price: inputs.recurPrice || '0'
          }}/>
        </Grid>}
      </>}
      {//* * deposit field **/
        (serviceType === 'move' || requestType === 'storageOnly') && <>
        <Grid item xs={6}>
          <TextField
            className={cn.textField}
            variant={TEXT_FIELD_VARIANT}
            label="Deposit"
            InputLabelProps={InputLabelProps}
            InputProps={{ inputComponent: MoneyNumberFormat }}
            value={inputs.deposit}
            onChange={onInputChange('deposit')}
            error={!!depositError}
            required
          />
          <HelperText error={!!depositError}>
            {depositError || 'Paid at time of booking'}
          </HelperText>
        </Grid>
      </>}
      {//* * move late cancellation **/
        serviceType.includes('move') && <>
        <Grid item xs={6}>
          <TextField
            className={cn.textField}
            variant={TEXT_FIELD_VARIANT}
            label="Late Cancel Fee"
            InputLabelProps={InputLabelProps}
            InputProps={{ inputComponent: MoneyNumberFormat }}
            value={inputs.lateCancelFee}
            onChange={onInputChange('lateCancelFee')}
            error={!!cancelFeeError}
            required
          />
          <HelperText error={!!cancelFeeError}>
            {cancelFeeError || 'Within 48 hrs of move.'}
          </HelperText>
        </Grid>
      </>}
      <Grid item xs={12}>
        <TextField
          className={cn.textField}
          variant={TEXT_FIELD_VARIANT}
          id="notes"
          label="Notes"
          InputLabelProps={InputLabelProps}
          value={inputs.notes}
          onChange={onInputChange('notes')}
          multiline
          error={!!notesError}/>
        <HelperText error={!!notesError}>
          {notesError || 'Sent to customer along with quote.'}
        </HelperText>
      </Grid>
    </Grid>
  </Form>
  )
}

const useStyles = makeStyles({
  quoteForm: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10
  },
  formSection: {
    marginTop: 5,
    marginBottom: 30
  },
  inputLabel: {
    color: '#176BB5',
    fontFamily: "'proxima-nova', 'Helvetica Neue', Helvetica, Arial, Sans-Serif",
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'uppercase',
    backgroundColor: '#FFFFFF',
    padding: '0 5px 0 0',
    whiteSpace: 'nowrap'
  },
  lessSpace: {
    marginTop: -15
  },
  textField: {
    width: '100%'
  },
  submitContainer: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center'
  }
})

export default ServiceForm
