import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Paper, Collapse, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'

const classes = (arr) => arr.filter(Boolean).join(' ')

const TRANSITION_MS = 500

const useStyles = makeStyles({
  overlapDiv: z => ({
    // gridColumnStart: 1,
    // gridRowStart: 1,
    width: '100%',
    marginLeft: '-100%',
    '&:first-of-type': {
      marginLeft: 'unset !important'
    },
    ...z && { zIndex: z }
  }),
  root: {
    opacity: 1,
    visibility: 'visible',
    transition: [
      `all ${TRANSITION_MS}ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`
    ]
  },
  fadeOut: {
    opacity: 0,
    visibility: 'hidden'
  },
  paper: {
    marginBottom: 30,
    background: 'white'
  },
  container: {
    position: 'relative',
    width: '100%',
    padding: '30px 30px 30px 30px',
    display: 'flex',
    flexDirection: 'column'
  },
  closeButton: {
    zIndex: 10,
    position: 'absolute',
    top: 10,
    right: 10
  }
})

const ActionDiv = ({ className, z, collapse, fade, onClose, children, log }) => {
  const
    cn = useStyles(z)
  const [fullRender, setFullRender] = useState(false)

  useEffect(
    () => { if (!collapse && !fade) setFullRender(true) },
    [collapse, fade]
  )

  return (
    <div className={classes([cn.overlapDiv, cn.root, fade && cn.fadeOut, className])}>
      <Paper elevation={1} className={cn.paper}>
        <Collapse in={!collapse} timeout={TRANSITION_MS} mountOnEnter>
          <div className={cn.container}>
            {fullRender && children}
            {!!onClose &&
              <IconButton
                className={cn.closeButton}
                size="medium"
                onClick={onClose}>
                <Close/>
              </IconButton>
            }
          </div>
        </Collapse>
      </Paper>
    </div>
  )
}

ActionDiv.propTypes = {
  collase: PropTypes.bool,
  fade: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any.isRequired
}

ActionDiv.defaultProps = {
  collapse: false,
  fade: false,
  onClose: null
}

export default ActionDiv
