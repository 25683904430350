/* eslint-disable max-len */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Dialog, Typography, Button, CircularProgress, Paper } from '@material-ui/core'
import logo from '../assets/logo.png'

const useStyles = makeStyles({
  completionDialog: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '50px'
  },
  messageArea: {
    whiteSpace: 'pre-wrap',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '20px',
    color: '#757575',
    fontWeight: '600',
    fontSize: '20px'
  },
  messageLine: {
    display: 'flex',
    justifyContent: 'center'
  },
  homeButton: {
    width: '240px',
    boxShadow: '0px 4px 10px -2px rgba(0,0,0,0.75)',
    marginBottom: '30px'
  },
  logo: {
    marginBottom: '16px'
  },
  tocArea: {
    margin: '8px 25px',
    maxWidth: '700px',
    boxShadow: 'inset 0px 0px 3px 1px rgba(0,0,0,0.75)',
    overflowY: 'scroll',
    maxHeight: '400px'
  },
  tocText: {
    margin: '8px'
  },
  updateArea: {
    margin: '20px 25px',
    maxWidth: '700px',
    backgroundColor: 'rgb(255, 244, 229)',
    color: 'rgb(102, 60, 0)'
  },
  submitButton: {
    // width: '240px',
    marginTop: '10px',
    backgroundColor: '#0E75BB',
    color: 'white',
    '&:hover': {
      backgroundColor: '#78A3C0'
    }
  },
  rejectButton: {
    marginTop: '24px',
    color: 'lightgray',
    width: '240px',
    fontWeight: '400'
  }
})

function TermsDialog ({
  modalOpen, onAccept, onReject, loading, details
}) {
  const styles = useStyles()
  const [reachedBottom, setReachedBottom] = useState(false)

  const trackTermsScroll = (element) => {
    if (reachedBottom) { return }
    const node = element.target
    if (node.scrollTop + node.clientHeight >= node.scrollHeight - 10) {
      setReachedBottom(true)
    }
  }

  return (
    <Dialog open={modalOpen} fullScreen>
      <div className={styles.completionDialog}>
        <img src={logo} alt="logo" className={styles.logo} />
        {details.termsAccepted &&
          <Paper className={styles.updateArea} elevation={3}>
            <Typography variant="h6" align="center">
              Updated Terms:
            </Typography>
            <Typography className={styles.tocText} variant="body2" align="left" component="div">
              <div dangerouslySetInnerHTML={{ __html: details.terms?.update }} />
            </Typography>
          </Paper>
        }
        <Typography variant="h4" align="center">
          Our Terms of Service
        </Typography>
        <div
          id="TERMS_SCROLL"
          className={styles.tocArea}
          onScroll={trackTermsScroll}>
          <Typography className={styles.tocText} variant="body2" align="left" component="div">
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: details.terms?.andConditions }} />
          </Typography>
        </div>
        {/* <div className={styles.submitContainer}> */}
        {loading
          ? (
          <CircularProgress />
            )
          : (
          <Button
            variant="contained"
            className={styles.submitButton}
            onClick={onAccept}
            disabled={!reachedBottom}>
              I Accept
          </Button>
            )}
        {/* </div> */}
        <Button
          variant="text"
          size="small"
          className={styles.rejectButton}
          onClick={onReject}>
          I Do Not Accept
        </Button>
      </div>
    </Dialog>
  )
}

TermsDialog.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  termsAndConditions: PropTypes.string
}

TermsDialog.defaultProps = {
  termsAndConditions: ''
}

TermsDialog.defaultProps = {}

export default TermsDialog
