import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress
} from '@material-ui/core'

const useStyles = makeStyles({
  alertButton: { color: '#FF0D0D' }
})

const RejectAlertDialog = ({
  open, handleClose, handleReject, submitting
}) => {
  const styles = useStyles()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="reject-alert"
      aria-describedby="reject-alert-description">
      <DialogTitle id="reject-alert">
        Confirm Quote Review Rejection
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="reject-alert-description">
          Are you sure?
          By clicking &quot;Reject Quote&quot; you will be giving up your chance to respond to this Quote Request.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {submitting
          ? <CircularProgress />
          : <>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button className={styles.alertButton} onClick={handleReject}>
            Reject Quote
          </Button>
        </>}
      </DialogActions>
    </Dialog>
  )
}

RejectAlertDialog.propTypes = {
  submitting: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired
}

RejectAlertDialog.defaultProps = {}

export default RejectAlertDialog
