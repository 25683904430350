import React from 'react'
import { create } from 'jss'
import jssExtend from 'jss-plugin-extend'
import { StylesProvider, jssPreset } from '@material-ui/styles'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import './App.css'
import ReviewQuotePage from './pages/ReviewQuotePage'
import HomePage from './pages/HomePage'
import { URLParams } from './utils/app.util'
// picker -- disabled for now
// import {MuiPickersUtilsProvider} from '@material-ui/pickers';
// import MomentUtils from '@date-io/moment';

const jss = create({ plugins: [jssExtend(), ...jssPreset().plugins] })

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 760,
      lg: 1280,
      xl: 1920
    }
  },
  palette: {
    primary: {
      main: '#176BB5'
    }
  }
})

function App () {
  const { linkID } = URLParams()

  return (
    <StylesProvider jss={jss}>
      <MuiThemeProvider theme={theme}>
        {linkID ? <ReviewQuotePage /> : <HomePage />}
      </MuiThemeProvider>
    </StylesProvider>
  )
}

export default App
