import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Button, TextField, CircularProgress, Fade } from '@material-ui/core'
import { URLParams } from '../utils/app.util'
import Alert from './Alert'

const Login = ({ auth, login, onLogin, loginLabel }) => {
  // firebase:
  const sendResetEmail = (email) => auth.sendPasswordResetEmail(email)
  const startPassReset = oobCode => auth.verifyPasswordResetCode(oobCode)
  const submitPassReset = (oobCode, newPass) => auth.confirmPasswordReset(oobCode, newPass)

  const cn = useStyles()
  const [submitting, setSubmitting] = useState(false)
  const [user, setUser] = useState('')
  const [pass, setPass] = useState('')
  const [pass2, setPass2] = useState('')

  // set to turn on send password reset mode
  const [forgotMode, setForgotMode] = useState(false)

  // not really used atm
  const [resetMode, setResetMode] = useState(URLParams().mode === 'resetPassword')
  const [oobCode] = useState(URLParams().oobCode)

  const [alertMode, setAlertMode] = useState()
  const [alertMsg, setAlertMsg] = useState('')
  const [flipShow, setFlipShow] = useState(false)
  const showAlert = () => setFlipShow(cur => !cur)
  const showError = msg => {
    setAlertMode('error')
    setAlertMsg(msg)
    showAlert()
  }
  const showSuccess = msg => {
    setAlertMode('success')
    setAlertMsg(msg)
    showAlert()
  }

  const onLoginSubmit = (evt) => {
    evt.preventDefault()
    setSubmitting(true)
    login(user, pass)
      .then(() => onLogin(true))
      .catch(err => showError(err.message))
      .finally(() => setSubmitting(false))
  }

  const onResetSubmit = (evt) => {
    evt.preventDefault()
    if (pass !== pass2) {
      showError('Passwords do not match')
      return
    }
    setSubmitting(true)
    submitPassReset(oobCode, pass)
      .then(() => {
        setResetMode(false)
        showSuccess('Reset Password Success')
      })
      .catch(err => showError(`Reset Password Failure: ${err.message}`))
      .finally(() => setSubmitting(false))
  }

  const onSendReset = evt => {
    evt.preventDefault()
    if (!user) {
      showError('No username')
      return
    }
    setSubmitting(true)
    sendResetEmail(user)
      .then(() => {
        showSuccess('Email sent')
        setForgotMode(false)
      })
      .catch(err => showError(err.message))
      .finally(() => setSubmitting(false))
  }

  const handleUserEdit = evt => setUser(evt.target.value)
  const handlePassEdit = evt => setPass(evt.target.value)
  const handlePass2Edit = evt => setPass2(evt.target.value)

  const handleForgot = evt => {
    evt.preventDefault()
    setForgotMode(cur => !cur)
  }

  useEffect(() => {
    if (resetMode && !user) {
      startPassReset(oobCode)
        .then((email) => {
          setUser(email)
          window.history.replaceState({}, 'Muva | Mover Access', '/')
        })
        .catch((err) => {
          showError(`Reset Failure: ${err.message}`)
          setResetMode(false)
          window.history.replaceState({}, 'Muva | Mover Access', '/')
        })
    }
  }, [resetMode, user, oobCode])

  return (
    <form className={cn.form}
      onSubmit={
          resetMode
            ? onResetSubmit
            : forgotMode
              ? onSendReset
              : onLoginSubmit}
    >

      {/* <div> // TODO: convert success too
        { error && <FormHelperText className={cn.errorMessage}>{error}</FormHelperText> }
        { success && <FormHelperText className={cn.successMessage}>{success}</FormHelperText>}
      </div> */}
      <Alert className={cn.alert} severity={alertMode} flipShow={flipShow}>
        {alertMsg}
      </Alert>
      <div id="LoginForm" className={cn.credentialsForm}>
        <TextField
          className={cn.input}
          label="User Name"
          autoComplete="on username"
          value={user}
          type="email"
          onChange={handleUserEdit}
          disabled={resetMode}
        />
        <Fade in={!forgotMode}>
          <TextField
            className={cn.input}
            label={resetMode ? 'New Password' : 'Password'}
            value={pass}
            type="password"
            autoComplete="on current-password"
            onChange={handlePassEdit}
          />
        </Fade>
        {resetMode && (
          <TextField
            className={cn.input}
            label="Retype Password"
            value={pass2}
            type="password"
            autoComplete="on current-password"
            onChange={handlePass2Edit}
          />
        )}
      </div>
      <div className={cn.loginSubmit}>
        {!submitting
          ? (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={cn.loginButton}>
            {resetMode
              ? 'Reset Password'
              : forgotMode
                ? 'Send Reset'
                : (loginLabel || 'Login')}
          </Button>
            )
          : (
          <CircularProgress />
            )}
      </div>
      <div>
        <Button
          disableRipple
          variant="text"
          color="primary"
          type="submit"
          // className={cn.loginButton}
          onClick={handleForgot}>
          {forgotMode ? 'Back' : 'Forgot your password?'}
        </Button>
      </div>
    </form>
  )
}

const useStyles = makeStyles({
  form: {
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  alert: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    z: 10
  },
  loginTitle: {
    display: 'flex',
    justifyContent: 'center'
  },
  credentialsForm: {
    width: '100%',
    maxWidth: 400,
    padding: '10px 40px 0 40px',
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    marginBottom: '10px'
  },
  loginSubmit: {
    height: 25,
    margin: '10px 0 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  loginButton: {
    backgroundColor: '#0E75BB',
    color: 'white',
    '&:hover': {
      backgroundColor: '#78A3C0'
    }
  },
  errorMessage: {
    display: 'flex',
    justifyContent: 'center',
    color: 'red',
    padding: '5px 0'
  },
  successMessage: {
    display: 'flex',
    justifyContent: 'center',
    color: 'green',
    padding: '5px 0'
  }
})

Login.propTypes = {
  setLoggedIn: PropTypes.func.isRequired,
  label: PropTypes.string
}

Login.defaultProps = {
  label: 'Login'
}

export default Login
