import { IconButton, Snackbar } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { loadEstimatorItems, storeEstimatorItems } from '../utils/app.util'

import ActionButton from './ActionButton'
import Alert from '@material-ui/lab/Alert'
import { Close } from '@material-ui/icons'
import EstimatorItem from './EstimatorItem'
import Select from 'react-select'
import _ from 'lodash'
import { estimatorItems } from 'utils/firebase.util'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  separator: {
    marginTop: '1em'
  },
  inlineButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '5px'
  }
})

export default function MyModal (props) {
  const styles = useStyles()
  const { showModal, setShowModal } = props
  const [showAlert, setShowAlert] = useState(false)
  const [categories, setCategories] = useState({})
  const [categorySelected, setCategorySelected] = useState(null)
  const [subCategorySelected, setSubCategorySelected] = useState(null)
  const [selectedItems, setSelectedItems] = useState({})

  useEffect(() => {
    async function getEstimatorItems () {
      const items = await estimatorItems()
      const categoriesTemp = items.reduce((acc, curr) => ({
        [curr.name]: curr.items.map((r) => ({ ...r, value: r.name, label: r.name, numberOfItems: 0, result: 0, category: curr.name })),
        ...acc
      }), {})
      setCategories(categoriesTemp)
    }
    getEstimatorItems() // from BE

    // check for locally stored selected items
    const storedItems = loadEstimatorItems()
    if (storedItems) setSelectedItems(storedItems)
  }, [])

  const addSelectedItem = () => {
    if (!subCategorySelected) return
    const tmp = { ...selectedItems }
    if (Array.isArray(tmp[categorySelected.value]?.items)) {
      if (_.some(tmp[categorySelected.value].items, subCategorySelected)) {
        setShowAlert(true)
      } else {
        tmp[categorySelected.value].items.push(subCategorySelected)
      }
    } else {
      tmp[categorySelected.value] = { items: [subCategorySelected] }
    }
    storeEstimatorItems(tmp)
    setSelectedItems(tmp)
    setSubCategorySelected({})
    setCategorySelected({})
  }

  const categoryOptions = Object.keys(categories).map((r) => ({ value: r, label: r }))

  const subcategoryOptions = (categories[categorySelected?.value] ?? [])

  const onCategoryChange = (e) => {
    setCategorySelected(e ?? null)
    setSubCategorySelected(null)
  }

  const onSubcategoryChange = (e) => {
    setSubCategorySelected(e ?? null)
  }

  const onRemoveItem = (item, index) => {
    const tmpSel = { ...selectedItems }
    tmpSel[item.category].items.splice(index, 1)
    if (tmpSel[item.category].items.length === 0) {
      delete tmpSel[item.category]
    }
    setSelectedItems(tmpSel)
  }

  const onClearAll = () => {
    setSelectedItems({})
    storeEstimatorItems({})
  }

  const handleClose = () => {
    setShowAlert(false)
  }

  return (
    <div
      className={showModal ? 'modal-dialog show' : 'modal-dialog'}
    >
      <div className="modal-content">
        <div className="modal-header">
          <IconButton
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setShowModal(false)
            }}
            size='small'
          >
            <Close />
          </IconButton>
          <h4 className="modal-title" id="myModalLabel">
            The Muva Estimator
          </h4>
        </div>
        <div className="modal-body">
          <Select
            options={categoryOptions}
            isClearable
            value={categorySelected}
            onChange={onCategoryChange}
            placeholder="Select a category"
          />
          <Select
            className={styles.separator}
            options={subcategoryOptions}
            isClearable
            value={subCategorySelected}
            onChange={onSubcategoryChange}
            placeholder="Select an item"
            isDisabled={subcategoryOptions.length === 0}
            styles={{ marginTop: '8px' }}
          />
          <div className={styles.separator}></div>
          <div className={styles.inlineButtons}>
            <ActionButton onClick={addSelectedItem} disabled={_.isEmpty(subCategorySelected)}>
              Add Item
            </ActionButton>
            <ActionButton onClick={onClearAll} disabled={_.isEmpty(selectedItems)}>
              Clear all
            </ActionButton>
          </div>
          <div className={styles.separator}></div>
          {Object.entries(selectedItems).map(([key, value], index) => (
            <div key={`${key}_${index}`}>
              <h5>{key}</h5>
              <EstimatorItem items={value.items} onRemove={onRemoveItem} />
            </div>
          ))}
        </div>
      </div>
      <Snackbar open={showAlert} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          This item is already added!
        </Alert>
      </Snackbar>
    </div>
  )
}
