/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Paper, Collapse } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Navbar from '../components/Navbar'
import {
  auth, login, logout,
  checkLogin,
  getMoverInfo,
  getStripeLink,
  verifyStripeConnect,
  deleteStripeConnect,
  verifyEmail,
  sendPasswordReset
} from '../utils/firebase.util'
import { Login } from 'lib'
import Loading from '../components/Loading'
import ActionButton from '../components/ActionButton'
import { URLParams, time } from '../utils/app.util'

const useStyles = makeStyles({
  login: {
    height: '80%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  loginForm: {
    height: 350,
    width: 400
  },
  alert: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  page: {
    margin: '40px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  actions: {
    width: '300px',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
})

const HomePage = () => {
  const cn = useStyles()
  const [loggedIn, setLoggedIn] = useState(null) // null | true | false
  const [mover, setMover] = useState(null) // null | mover {}
  const [loadingLink, setLoadingLink] = useState(false)
  const [connectResult, setConnectResult] = useState(null) // null | true | false

  const [verifyResult, setVerifyResult] = useState(null) // null | true | false
  const [verifyMsg, setVerifyMsg] = useState('')

  const [resetEmailRes, setResetEmailRes] = useState(null)

  const resetPassword = async () => {
    await sendPasswordReset()
      .then(() => setResetEmailRes({
        severity: 'success',
        message: 'Password reset email sent'
      }))
      .catch(() => setResetEmailRes({
        severity: 'error',
        message: 'Failed to send reset email. Please try again.'
      }))
  }

  useEffect(() => {
    checkLogin()
      .then(res => {
        if (res) {
          const loginTime = time(res.metadata.lastSignInTime)
          const loginLimit = time().subtract(1, 'minutes')

          if (loginTime.isBefore(loginLimit)) {
            logout()
          } else {
            setLoggedIn(true)
          }
        } else {
          setLoggedIn(false)
        }
      })
  }, [])

  useEffect(() => {
    // submits stripe auth code (if present) before loading mover info
    const load = async () => {
      const { code, mode, oobCode } = URLParams()

      if (code) {
        await verifyStripeConnect(code)
          .then(() => {
            setConnectResult(true)
            window.history.replaceState({}, 'Muva | Mover Access', '/')
          })
          .catch(() => setConnectResult(false))
      }

      if (mode === 'verifyEmail') {
        await verifyEmail(oobCode)
          .then(() => {
            setVerifyResult(true)
            window.history.replaceState({}, 'Muva | Mover Access', '/')
          })
          .catch((err) => {
            setVerifyResult(false)
            setVerifyMsg(err.message)
            window.history.replaceState({}, 'Muva | Mover Access', '/')
          })
      }

      getMoverInfo().then(info => setMover(info))
    }

    if (loggedIn) {
      load()
    }
  }, [loggedIn])

  const accessStripe = async () => {
    setLoadingLink(true)
    const link = await getStripeLink()
    window.location.href = link
  }

  // const resetPassword = () => {

  // };

  return (
    <>
      <Navbar
        title={mover ? mover.name : 'Mover Secure Access'}
        loggedIn={loggedIn}
        hideContact={!loggedIn}
      />
      <Collapse in={connectResult !== null}>
        { connectResult === true
          ? (
            <Alert className={cn.alert} severity="success">
              Stripe connect success!
            </Alert>
            )
          : (
            <Alert className={cn.alert} severity="error">
              Stripe account not created properly. Please contact us.
            </Alert>
            )
        }
      </Collapse>
      <Collapse in={verifyResult !== null}>
        { verifyResult === true
          ? (
            <Alert className={cn.alert} severity="success">
              Email Verifed!
            </Alert>
            )
          : (
            <Alert className={cn.alert} severity="error">
              <span>
                <b>Email verification failed:</b> {verifyMsg}
              </span>
            </Alert>
            )
        }
      </Collapse>
      <Collapse in={mover && !mover.sentProofOfInsuranceNotice}>
        <Alert className={cn.alert} severity="warning">
          <span>
            <b>No payout will be allowed</b> until proof of insurance
            naming Muva Inc as additionally insured is provided.
          </span>
        </Alert>
      </Collapse>
      <Collapse in={resetEmailRes !== null}>
        { resetEmailRes && (
          <Alert className={cn.alert} severity={resetEmailRes.severity}>
            {resetEmailRes.message}
          </Alert>
        )}
      </Collapse>
      { loggedIn === null
        ? <div />
        : loggedIn === false
          ? <div className={cn.login}>
          <Paper className={cn.loginForm} elevation={3}>
            <Login auth={auth} login={login} onLogin={setLoggedIn} />
          </Paper>
        </div>
          : !mover
              ? <Loading />
              : (
        <div className={cn.page}>
          <Paper elevation={4} className={cn.actions}>
            <ActionButton onClick={accessStripe} loading={loadingLink}>
              {mover.hasStripe ? 'Access Stripe Payouts' : 'Setup Stripe Payouts'}
            </ActionButton>
            <ActionButton onClick={resetPassword} spaced>
              Reset Password
            </ActionButton>
            { process.env.NODE_ENV === 'development' && (
              <>
                <div style={{ margin: 20 }}>Dev Actions:</div>
                { mover.hasStripe && (
                  <ActionButton onClick={deleteStripeConnect}>
                    Delete Stripe Account
                  </ActionButton>
                )}
              </>
            )}
          </Paper>
        </div>
                )}
    </>
  )
}

export default HomePage
