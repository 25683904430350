import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, FormHelperText } from '@material-ui/core'

const HelperText = ({ children, error }) => {
  const cn = useStyles()
  return (
    error
      ? <FormHelperText className={cn.helpText} error>{children}</FormHelperText>
      : <Typography className={cn.helpText} variant="body2">{children}</Typography>
  )
}

const useStyles = makeStyles({
  helpText: {
    margin: '0 10px',
    fontSize: 12,
    whiteSpace: 'pre-wrap'
  }
})

export default HelperText
